import { useEffect, useRef } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { ILeaderBoardItem } from "../../app/entities";
import { useAppSelector } from "../../app/hooks";
import { playerSelector } from "../../features/game/gameSlice";

interface Props extends ILeaderBoardItem {
  index: number;
  prevPlace: number;
  currentUserId?: string | null;
  afterGameView: boolean;
}

const PlayerItem = ( props: Props ) => {
  const { playerId, position, name, score, index, prevPlace, afterGameView } = props;

  const player = useAppSelector(playerSelector);

  const placeImg = position! > 3 ? position : <img alt='place-icon' src={`/img/players/${position}.svg`} />;
  const randomName = Math.floor(Math.random()  * 3 );
  const repeated = new Array(100).fill([0, 1, 2, 3]).flat();
  const avatarName = repeated[index]? repeated[index] : randomName;

  const itsYou = afterGameView && player?.playerId === playerId;
  const className = itsYou? 'you' : '';
  const isPause = prevPlace + 1 !== position;

  let myPositionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const footerEl = document.getElementsByClassName('scroll-container');
    if(itsYou) {
      const r = document.querySelector(':root') as any;
      const header = getComputedStyle(r).getPropertyValue('--header') as any;
      setTimeout(() => {
        footerEl[0].scrollTo(0, myPositionRef!.current!.offsetTop - parseInt(header) - 20);
      }, 1000);
    } else if (!afterGameView && index === 0) {
      setTimeout(() => {
        footerEl[0].scrollTo(0, 0);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterGameView, itsYou]);


  return (
    <div className='playerItem-container' ref={myPositionRef}>
      { isPause && <div className="line"></div> }
      <Grid 
        container 
        justifyContent='space-between' 
        width='inherit' 
        className={className}
      >
        <Grid item className='item place text-ellipsis'>
          { position! > 3 ? <span className='text-ellipsis'>{ placeImg }</span> : placeImg }
        </Grid>
        <Grid item xs className='item player-name text-ellipsis'>
          <img alt='avatar' src={`/img/players/avatar/${avatarName}.svg`} className="avatar"/>
          <span className='text-ellipsis'>{ name }</span>
        </Grid>
        <Grid item className='item score'>
          <img alt='score' src='/img/players/coin.svg' />
          <Box 
            className='d-flex justify-content-end score-item'>
              { score }
          </Box>
        </Grid>

        {
          itsYou && <div  className='arrow-container d-flex align-items-center'>
            <span>You</span>
            <img alt='arrow' src='/img/players/arrow.svg' />
          </div>
        }
      </Grid>
    </div>
  );
};

export default PlayerItem;
