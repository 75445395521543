import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from '@mui/material/useMediaQuery';

import { loadCountOfPlayers } from "../../services/firestore";

const Players = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  let timeout: any = null;

  const [playersCount, setPlayersCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect( () => {
    getPlayersCount();

    return () => {
      clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getPlayersCount  = async () => {
    setLoading(true);
     timeout = setTimeout(async () => {
      const _playersCount = await loadCountOfPlayers();
      setPlayersCount(_playersCount);
      setLoading(false);
    }, 3000);
  };

  return (
    <Box
      className="d-flex align-items-center justify-content-center"
      sx={{
        background: "#282828",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        borderRadius: "64px",
        padding: isSm? "8px 16px 8px 8px" : isMd? "8px 24px 8px 8px" : "5px 24px 5px 8px",
        height: isSm? "40px" : isMd? "52px" : '64px',
      }}
    >
      <img alt="players" src="/img/players/people.svg" width={isSm? 25 : isMd ? 40 : 48}/>
      <Box 
        sx={{ 
          fontWeight: 400,
          fontSize: isSm? '14px' : isMd ? '16px' : '20px',
          lineHeight: isSm? '16px' : isMd ? '19px' : '23px',
          pr: '4px',
          ml: isSm? '8px' : isMd ? "16px" : "20px"
        }}
      >
        Players:
      </Box>
      <Box 
        sx={{ 
          fontWeight: 700,
          fontSize: isSm? '16px' : isMd ? '20px' : '24px',
          lineHeight: isMd ? '20px' : '28px',
          minWidth: isSm? '35px' : '70px',
          textAlign: 'end'
        }}
      >
        { loading? <CircularProgress size={30} sx={{ position: 'relative', top: '4px', left: '0'}} /> : playersCount }
      </Box>
    </Box>
  );
};

export default Players;
