import { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  correctPercentAnswersSelector,
  recalculateGame,
} from "../../features/game/gameSlice";
import TimeOverCalculation from "./TimeOverCalculation";

const TimeOver = () => {
  const dispatch = useAppDispatch();

  const correctPercentAnswers = useAppSelector(correctPercentAnswersSelector);
  const message = `You only guessed ${correctPercentAnswers}% right!`;

  const _text = useMemo(() => {
    let text = "";
    if (correctPercentAnswers! <= 10) {
      text = "My Grandpa";
    } else if (correctPercentAnswers! <= 20) {
      text = "Andreessen Horowitz";
    } else if (correctPercentAnswers! <= 30) {
      text = "Sequoia Capital";
    } else if (correctPercentAnswers! <= 40) {
      text = "Index Ventures";
    } else if (correctPercentAnswers! <= 50) {
      text = "The Lannisters";
    } else if (correctPercentAnswers! <= 60) {
      text = "Tony Stark";
    } else if (correctPercentAnswers! <= 70) {
      text = "Bruce Wayne";
    } else if (correctPercentAnswers! <= 87) {
      text = "Montgomery Burns";
    } else if (correctPercentAnswers! <= 100) {
      text = "Prelaunch.com (come work for us)";
    }
    return text;
  }, [correctPercentAnswers]);

  useEffect(() => {
    dispatch(recalculateGame());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="time-over">
      <div className="d-flex text">Time is up!</div>
      <div className="d-flex title">{message}</div>
      <div className="sub-title">
        {`You are as good as ${_text} at guessing a product's success.`}
        <br />
        Want to do better?
      </div>
      <div className="d-flex align-items-center flex-column timer-c">
        <TimeOverCalculation />
      </div>
    </div>
  );
};

export default TimeOver;
