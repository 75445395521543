import { Timestamp } from "@firebase/firestore";

import { doc, setDoc, collection, query, deleteDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";
var Airtable = require("airtable");

const { REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_API_BASE } = process.env;

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: REACT_APP_AIRTABLE_API_KEY,
});
var base = Airtable.base(REACT_APP_AIRTABLE_API_BASE);

const createdTimestamp = (date: 'string') => Timestamp.fromDate(new Date(date));

const getDataFromField = (fields: any, isSuccessfull: boolean) => (
  {
    name: fields.Name ? fields.Name : '',
    description: fields.Description ? fields.Description : '',
    launch_date: fields["Launch Date"] ? createdTimestamp(fields["Launch Date"]) : null,
    CF_Platform: fields["CF Platform"] ? fields["CF Platform"] : '',
    product_category: fields["Product Category"] ? fields["Product Category"] : '',
    price: fields['Price (that they sell the product on their website)']? fields['Price (that they sell the product on their website)'] : null,
    raised_money: fields["Raised money"] ? fields["Raised money"] : null,
    "#_of_backers": fields["# of backers"] ? fields["# of backers"] : '',
    "CF(KS/IGG)_page": fields["CF (KS/IGG) page"]? fields["CF (KS/IGG) page"] : '',
    product_picture: fields["Product Picture"] ? fields["Product Picture"] : '',
    lifestyle_picture: fields["Lifestyle Picture"] ? fields["Lifestyle Picture"] : '',
    isSuccessfull,
  }
);

export const setProductsItem = async (
  tableName: string,
  isSuccessfull: boolean
) => {
  const productsRef = collection(db, "products");
  let promises: Array<any> = [];

  return new Promise((resolve: any, reject: any) => {
    base(tableName)
      .select({
        view: "Do not filter here",
      })
      .eachPage(
        function page(records: any, fetchNextPage: any) {
          try {
            records.forEach(function (record: any) {
              const { fields } = record;
              const _field = getDataFromField(fields, isSuccessfull);
              promises.push([setDoc(doc(productsRef), _field)]);
            });
          } catch (e) {
            console.log("error inside eachPage => ", e);
          }
          fetchNextPage();
        },
        function done(err: any) {
            if (err) {
              console.error(err);
              reject()
            } else {
              Promise.all(promises).then(() => resolve());
            }
        }
      );
  });
};

export const setPairedProducts = async () => {
    return new Promise ((resolve: any, reject: any) => {
      let promises: Array<any> = [];

        Promise
            .all([
                promiseHandpicked('Successful Handpicked', true), 
                promiseHandpicked('Failed Handpicked', false),
                promisePairs()
            ])
            .then((data: any) => {
                const success = data.find((item: any) => item.success)?.success;
                const faild = data.find((item: any) => item.faild)?.faild;
                const pairs = data.find((item: any) => item.pairs)?.pairs;

                const productsRef = collection(db, "pairedProducts");

                pairs.forEach((item: {Successful: string, Fail: string}) => {
                    const successData = success[parseInt(item.Successful) - 1];
                    const faildData = faild[parseInt(item.Fail) - 1];

                    if (successData && faildData) {
                        const _field = {
                            isSuccess: successData,
                            isFaild: faildData
                        };

                        promises.push(setDoc(doc(productsRef), _field));
                    }
                });
            });

            Promise.all(promises).then(() => {
              resolve();
            });
    })
};

const promisePairs = () => new Promise((resolve: any, reject: any) => {
    let pairs: Array<any> = [];

    base('Handpicked Products').select({ 
        view: "Grid view"
    })
        .eachPage(
        function page(records: any, fetchNextPage: any) {
        try {
            records.forEach(function(record: any) {
                const { fields } = record;
                pairs.push(fields);
            });
        } catch(e){ console.log('error inside eachPage => ',e)}
            fetchNextPage();
        },
        function done(err: any) {
            if (err) {
                console.error(err);
                reject();
            } else {
                resolve({ pairs });
            }
        }
    );
});

const promiseHandpicked = (tableName: string, isSuccessfull: boolean) => new Promise((resolve: any, reject: any) => {
    let products: Array<any> = [];

    base(tableName).select({ 
        view: "Do not filter here"
    })
    .eachPage(
        function page(records: any, fetchNextPage: any) {
        try {
            records.forEach(function(record: any) {
                const { fields } = record;
                const _field = getDataFromField(fields, isSuccessfull);;
                products.push(_field);
            });
        } catch(e){ console.log('error inside eachPage => ',e)}
            fetchNextPage();
        },
        function done(err: any) {
            if (err) {
                console.error(err);
                reject();
            } else {
                if (isSuccessfull) {
                    resolve({ success: products });
                } else {
                    resolve({ faild: products });
                }
            }
        }
    );
});

const deleteCollection = async (collectionName: string) => {
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(q, resolve).catch(reject);
  });
};

const deleteQueryBatch = async (q: any, resolve: any) => {
  const snapshot = await getDocs(q);

  snapshot.docs.forEach(async (doc: any) => {
    await deleteDoc(doc.ref);
  });

  resolve();
};

export const setProducts = async () => {
  return Promise.all([
    deleteCollection("products"),
    deleteCollection("pairedProducts"),
    setProductsItem("Successful Products", true), 
    setProductsItem("Failed Products", false), 
    setPairedProducts()
  ]);
};
