export const EmailRegExpr = /^[^\s]+@[^\s]+\.[^\s]+$/;

export const preloadedImg = (src: string) => {
    const img = new Image();
    img.src = src;
    return img;
}


export const shuffle = (array: Array<any>) => {
    return array.sort(() => Math.random() - 0.5);
  }