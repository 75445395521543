import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import Box from "@mui/material/Box";

import { EmailRegExpr } from "../../../helpers";
import { LoginForm } from "./Form";
import { AdminRoutes } from "../../../constants/routes";
import { useAppDispatch } from "../../../app/hooks";
import { IAdmin, logIn } from "../../../features/admin/adminSlice";

export interface AdminLoginState {
  email: string;
  password: string;
}

export interface AdminLoginErrors {
  general?: string;
  email?: string;
  password?: string;
}

const AdminLoginPage = () => {
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const initialState: AdminLoginState = {
    email: "",
    password: "",
  };

  const [values, setValues] = useState<AdminLoginState>(initialState);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [errors, setErrors] = useState<AdminLoginErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    setErrors({});
    setValues((prev: AdminLoginState) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isValid = () => {
    let newErrors: AdminLoginErrors = {};
    const { email, password } = values;

    if (email.trim() === "" || !EmailRegExpr.test(email.trim())) {
      newErrors.email = "This field is required";
    }

    if (password.trim() === "") {
      newErrors.password = "This field is required";
    } else if (password.trim().length < 6) {
      newErrors.password = "Password must contain more then 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const doLogin = () => {
    if (isValid()) {
      setLoading(true);
      const auth = getAuth();
      const { email, password } = values;

      signInWithEmailAndPassword(
        auth,
        email.trim(),
        password.trim()
      )
        .then((userCredential: any) => {
          const user = userCredential.user as IAdmin;
          dispatch(logIn({ email: user.email.trim(), accessToken: user.accessToken}));
          history(AdminRoutes.Dashboard.main);
        })
        .catch((error: any) => {
          const errorCode = error.code;
          setErrors({ general: errorCode });
          setLoading(false);
        });
    }
  };

  return (
    <div className="Admin-container">
      <Box sx={{ my: 2, color: 'red', fontSize: '16px'}}>
        {errors.general}
      </Box>

      <LoginForm
        values={values}
        errors={errors}
        loading={loading}
        handleChange={handleChange}
        doLogin={doLogin}
      />
    </div>
  );
};

export default AdminLoginPage;
