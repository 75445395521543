import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface IHeaderSize {
  header: number;
  footer: number;
}
interface IProps {
  isLoading?: boolean;
}

const useHeaderSize = (props: IProps):IHeaderSize => {
  const { isLoading } = props;
  const location = useLocation();

  const initialState = {
    header: 0,
    footer: 0
  };
  const [sizes, setSizes] = useState<IHeaderSize>(initialState);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [isLoading, location]);

  function handleResize() {
    const headerEl = document.getElementsByClassName('app-header');
    const headerHeigth = headerEl && headerEl[0] ? headerEl[0].clientHeight : 0;
    const footerEl = document.getElementsByClassName('leaderboard-actions');
    const footerHeigth = footerEl && footerEl[0] ? footerEl[0].clientHeight : 0;

    const header = Math.ceil(headerHeigth) + 1;
    const footer = Math.ceil(footerHeigth) + 1;

    const r = document.querySelector(':root') as any;
    r.style.setProperty('--header', `${header + 5}px`);

    setSizes({
      header,
      footer
    });
  }
  
  return {
    header: sizes.header,
    footer: sizes.footer,
  };
};

export default useHeaderSize;
