import { useState, useRef } from "react";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";

import ButtonApp from "../../../app/Shared/Button";
// import AppCheckbox from "../../../app/Shared/AppCheckbox";
import { EmailRegExpr } from "../../../helpers";

import { useSaveGameInfo } from "../../../helpers/useGameHook";

export interface State {
  email: string;
  name: string;
}

export interface Errors {
  general?: string;
  email?: string;
  name?: string;
}

const ResultsForm = () => {
  const saveGameInfo = useSaveGameInfo();

  const initialState: State = {
    email: "",
    name: "",
  };

  const [values, setValues] = useState<State>(initialState);
  // const [subscribe, setSubscribe] = useState<boolean>(true);
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [skipLoading, setSkipLoading] = useState<boolean>(false);

  const emailField = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    setErrors({});
    setValues((prev: State) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleChangeSubscribe = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setSubscribe(event.target.checked);
  // };

  const handleEnterKey = (field: string) => (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      switch (field) {
        case "name":
          emailField?.current?.focus();
          break;

        case "email":
          doSendResults();
          break;

        default:
          break;
      }
    }
  };

  const isValid = () => {
    let newErrors: Errors = {};
    const { email, name } = values;

    if (email.trim() === "" || !EmailRegExpr.test(email)) {
      newErrors.email = "This field is required";
    }
    if (name.trim() === "") newErrors.name = "This field is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const doSendResults = async () => {
    if (isValid()) {
      setLoading(true);

      const { email, name } = values;
      const playerData = {
        name,
        email,
        subscribe: true,
        isAnonymus: false
      };

      await saveGameInfo(playerData);
    }
  };

  const onSkip = async () => {
    setSkipLoading(true);

    const playerData = {
      name: 'Anonymus',
      email: '',
      subscribe: false,
      isAnonymus: true
    };

    await saveGameInfo(playerData);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
        <div className="form-title">
          Where should we send it? (Your Email)
        </div>

        <FormControl className='form-container' sx={{ mb: "24px" }} variant="filled" fullWidth>
          <OutlinedInput
            id="filled-adornment-name"
            placeholder="Full Name"
            value={values.name}
            name="name"
            className="input"
            fullWidth
            onChange={handleChange}
            onKeyUp={handleEnterKey("name")}
            autoComplete='off'
            error={errors && Boolean(errors.name)}
          />
          <FormHelperText className="error">{errors.name}</FormHelperText>
        </FormControl>
        <FormControl className='form-container' variant="filled" fullWidth>
          <OutlinedInput
            id="filled-adornment-email"
            value={values.email}
            placeholder="Email"
            name="email"
            className="input"
            fullWidth
            onChange={handleChange}
            onKeyUp={handleEnterKey("email")}
            autoComplete='off'
            error={errors && Boolean(errors.email)}
            inputRef={emailField}
          />
          <FormHelperText className="error">{errors.email}</FormHelperText>
        </FormControl>
      </Box>
      {/* <Box className="text-left d-flex align-items-center label-container">
        <AppCheckbox
          checked={subscribe}
          onChange={handleChangeSubscribe}
          label={
            <div className="agree">
              I agree to receive emails with the free deals, coupon codes and
              offers.
            </div>
          }
        />
      </Box> */}
      <div className='form-container'>
        <ButtonApp
          size="large"
          fullWidth
          className="send-result-btn form-container"
          onClick={doSendResults}
          loading={loading}
          disabled={loading}
        >
          {`Unlock Results & 20% Off`}
        </ButtonApp>
      </div>  
      <Box className="skip form-container">
        <LoadingButton 
          variant="text" 
          fullWidth
          loading={skipLoading}
          disabled={skipLoading} 
          onClick={onSkip}
        >
          Skip
        </LoadingButton>
      </Box>
    </>
  );
};

export default ResultsForm;
