import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import Portal from "@mui/material/Portal";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getPadTime } from "../../helpers/timer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  configsDataSelector,
  isSecFormatSelector,
} from "../../features/configs/configsSlice";
import { durationAnimation } from "../../constants";
import { GameRoutes } from "../../constants/routes";
import { checkQuestionsAsync, isGameStoppedSelector, stopTimer } from "../../features/game/gameSlice";

interface GameTimerContainerProps {
  children: any;
}

const GameTimerContainer = (props: GameTimerContainerProps) => {
  const location = useLocation();
  const isGameOverPage = location.pathname === GameRoutes.GameOver.main;

  const theme = useTheme();
  const isTabledView = useMediaQuery(theme.breakpoints.down(1220));
  const container = document.getElementById("gamePage-timer");

  if (isTabledView) {
    if(isGameOverPage) {
      return null;
    } else {
      return <Portal container={container}>{props.children}</Portal>;
    }
  } else {
    return props.children;
  }
};

const GameTimer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isGameStopped = useAppSelector(isGameStoppedSelector);
  const isGamePage = location.pathname === GameRoutes.Game.main;
  const isGameOverPage = location.pathname === GameRoutes.GameOver.main;

  const configs = useAppSelector(configsDataSelector);
  const isSecFormat = useAppSelector(isSecFormatSelector);
  const game_time_in_seconds = configs?.game_time_in_seconds
    ? configs?.game_time_in_seconds
    : 30;

  const [timeLeft, setTimeLeft] = useState<number>(isGameOverPage? 0 : game_time_in_seconds * 1000); //in miliseconds

  const minutes = getPadTime(Math.floor(timeLeft / 60000));
  const seconds = getPadTime(
    Math.floor(
      (isSecFormat ? timeLeft / 1000 : timeLeft - parseInt(minutes) * 60000) /
        (isSecFormat ? 1 : 1000)
    )
  );
  const miliseconds = getPadTime(
    Math.ceil(
      isSecFormat
        ? timeLeft - parseInt(seconds) * 1000
        : timeLeft - parseInt(minutes) * 60000 - parseInt(seconds) * 1000
    ) / 10
  );

  useEffect(() => {
    let timeout: any = null;
    if(isGamePage) {
      timeout = setTimeout(() => {
        dispatch(stopTimer(false));
      }, durationAnimation * 1000);
    }
    
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if( timeLeft === 0 ) {
      // navigate(GameRoutes.GameOver.main);
    }

    const interval = setInterval(() => {
      isGamePage && !isGameStopped && setTimeLeft((prev: number) => (prev === 0 ? 0 : prev - 10)); //isCounting
    }, 10);

    if (isGameStopped) {
      onStop();
    } else {
      onStart();
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameStopped]); //isCounting

  useEffect(() => {
    if( isGamePage && timeLeft === 0 ) {
      dispatch(stopTimer(true));
      navigate(GameRoutes.GameOver.main);
      dispatch(checkQuestionsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const onStart = () => {
    isGameStopped && dispatch(stopTimer(false));
  };

  const onStop = () => {
    if (!isGameStopped) {
      dispatch(stopTimer(true));
    }
  };

  return (
    <GameTimerContainer>
      <motion.div
        className="game-timer"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          type: "linear",
          duration: durationAnimation,
        }}
      >
        <img alt="clock" src="/img/clock.svg" />
        {isSecFormat
          ? `${seconds} : ${miliseconds}ms`
          : `${minutes}:${seconds} : ${miliseconds}ms`}
      </motion.div>
    </GameTimerContainer>
  );
};

export default GameTimer;
