import { useEffect, useState } from 'react';
import { motion } from "framer-motion"

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from '@mui/material/useMediaQuery';

import { answersSelector, gameScoreSelector } from "../../../features/game/gameSlice";
import { useAppSelector } from "../../hooks";

const GameInfo = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { correct, wrong } = useAppSelector(answersSelector);
  const score = useAppSelector(gameScoreSelector);

  const [startAnimation, setStartAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (score > 0) {
      setStartAnimation(true);
      setTimeout(()=>{
        setStartAnimation(false);
      }, 300);
    }
  }, [score]);


  return (
    <Box className="d-flex align-items-center justify-content-center">
      <Box
        className="d-flex align-items-center justify-content-center"
        sx={{
          background: "#282828",
          borderRadius: "56px",
          padding: "8px 16px 8px 24px",
          height: isSm? "40px" : "48px",
          fontWeight: 700,
          fontSize: isSm? "16px" : isMd? "20px" : "24px",
          lineHeight: isSm? "24px" : isMd? "32px" : "32px",
          marginRight: isSm? '8px' : isMd? '11px' : '16px'
        }}
      >
        <Box
          sx={{
            fontWeight: 400,
            fontSize: isSm? '12px' : isMd? "14px" : '16px',
            lineHeight: '20px', 
            marginRight: isSm? '8px' : isMd? '12px' : '16px',
            display: isSm? 'none' : 'block'
          }}
        >
          Your Guesses:
        </Box>
        <Box sx={{ marginRight: "8px"}}>{ correct }</Box>
          <img
            alt="correct"
            src="/img/game/correct.svg"
            width={ isSm? 18 : isMd? 24 : 28}
            height={ isSm? 18 : isMd? 24 : 28}
          />
        <Box sx={{ margin: isSm? "0 4px 0 8px" : "0 8px 0 16px"}}>{ wrong }</Box>
          <img
            alt="wrong"
            src="/img/game/wrong.svg"
            width={ isSm? 18 : isMd? 24 : 28}
            height={ isSm? 18 : isMd? 24 : 28}
          />
      </Box>


      {/*  count part  */}
      <Box
        className="d-flex align-items-center justify-content-center"
        sx={{
          background: "#282828",
          borderRadius: "44px",
          padding: isSm? "8px 16px 8px 0" : "8px 32px 8px 0",
          height: isSm? "40px" : "48px",
        }}
      >
        <Box sx={{ pr: isSm? "2px" : isMd? "18px" : "29px" }}>
          <motion.img
            alt="players"
            src="/img/players/coin.svg"
            width={isSm? 37 : 47}
            height={isSm? 40 : 49}
            initial={{ scale: 1 }}
            animate={{ scale: startAnimation? 1.3 : 1 }}
            transition={{
              type: "linear",
              duration: 0.3,
            }}
          />
        </Box>
        <Box
          sx={{
            fontWeight: 700,
            fontSize: isSm? "16px" : "24px",
            lineHeight: isSm? "24px" : "32px",
            minWidth: isSm? "38px" : "50px",
            textAlign: "end",
          }}
        >
          { score }
        </Box>
      </Box>
    </Box>
  );
};

export default GameInfo;
