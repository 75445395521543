import { useState } from "react";

import { LoadingButton } from "@mui/lab";

import { setProducts } from "../../../helpers/airtable";
import ConfirmationDialog from "../../../app/Shared/ConfirmationDialog";

const Synchronize = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isSyncLoading, setSyncLoading] = useState<boolean>(false);

  const onSync = async () => {
    setOpen(false);
    setSyncLoading(true);
    setProducts().then(() => {
      setSyncLoading(false);
    });
  };

  return (
    <>
      <LoadingButton
        sx={{ fontSize: "24px" }}
        variant="contained"
        loading={isSyncLoading}
        onClick={() => setOpen(true)}
      >
        Sync Products
      </LoadingButton>

      {open && <ConfirmationDialog
        open={open}
        maxWidth="xs"
        message="Are you sure you want to synchronize products?"
        onConfirm={onSync}
        onCancel={() => setOpen(false)}
      />}
    </>
  );
};

export default Synchronize;
