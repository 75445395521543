import { motion } from "framer-motion";

import TimeOver from "./TimeOver";
import "./styles.scss";

const GameOverPage = () => {

  return (
    <>
      {/* <div id="gamePage-timer" className="gamePage-timer-container" /> */}
      <div className="game-over-container d-flex flex-column align-items-center">
            <motion.div
              layout
              initial={{ opacity: 0, height: "auto" }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, height: 0 }}
              transition={{
                type: "linear",
              }}
            >
              <TimeOver />
            </motion.div>
      </div>
    </>
  );
};

export default GameOverPage;
