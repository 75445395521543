import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAnswers, IHandpickedProduct, IPlayer, IProduct, ISaveGameAnswers } from '../../app/entities';
import { loadAllProducts, loadHandpickedProducts } from '../../app/services/firestore';
import { RootState } from '../../app/store';
import { shuffle } from '../../helpers';

interface IGameState {
  questions?: Array<Array<IProduct>>;
  handpickedQuestions?: Array<Array<IProduct>>;
  products?: {
    isSuccess: Array<IProduct>;
    isFaild: Array<IProduct>;
  };
  handpickedProducts: Array<IHandpickedProduct>;
  player?: IPlayer;
  isStopped: boolean;
  answers: IAnswers;
  score: number;
  recaluculate: number;
  currentIndex: number;
  currentHandpickedIndex: number;
} 

const initialState: IGameState = {
  handpickedProducts: [],
  isStopped: true,
  answers: {
    correct: 0,
    wrong: 0,
    correctPercent: 0,
    wrongPercent: 0,
    data: []
  },
  score: 0,
  recaluculate: 0,
  currentIndex: 0,
  currentHandpickedIndex: 0
};

export const checkQuestionsAsync = createAsyncThunk('questions/check', async (args, { getState, dispatch }) => {
  const state = getState() as RootState;
  if (state.game.questions!.length - state.game.currentIndex < state.configs.data!.min_questions_for_new_game!) {
    dispatch(generateQuestions());
  }
  
  if (state.game.handpickedQuestions!.length - state.game.currentHandpickedIndex < 3) {
    dispatch(generateHandpickedQuestions());
  }
});

export const initDataLoading = createAsyncThunk('init', async (args, { dispatch }) => {
  await dispatch(loadProductsAsync());
  await dispatch(loadHandpickedProductsAsync());
  dispatch(generateHandpickedQuestions());
  dispatch(generateQuestions());
});

export const loadHandpickedProductsAsync = createAsyncThunk(
  'products/load/handpicked',
  async () => {
    return (await loadHandpickedProducts()).map((product: IHandpickedProduct) => {
      const { id, isSuccess, isFaild } = product;

      const successQuestion: IProduct = {
        ...isSuccess, 
        id: `${id}-isSuccess`
      };
      const faildQuestion: IProduct = {
        ...isFaild, 
        id: `${id}-isFaild`
      };

      const handpickedProduct: IHandpickedProduct = {
        id,
        isSuccess: successQuestion,
        isFaild: faildQuestion
      };

      return handpickedProduct;
    });
  }
);

export const loadProductsAsync = createAsyncThunk(
  'products/load',
  async () => {
    return (await loadAllProducts())
    .filter((item: IProduct) =>
      Boolean(item.productPicture)// !Object.values(item).includes('') && !Object.values(item).includes(null)
    ).map((product: IProduct) => {

      const question: IProduct = {
        ...product
      };

      return question;
    });
  }
);

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    clearUpGame: (state: IGameState) => {
      state = initialState;
    },
    clearUpGameInfo: (state: IGameState) => {
      state.isStopped = true;
      state.answers = {
        correct: 0,
        wrong: 0,
        correctPercent: 0,
        wrongPercent: 0,
        data: []
      };
      state.score = 0;
      state.recaluculate = 0;
    },
    recalculateGame: (state: IGameState) => {
      const countOfQuestions = state.answers.data.length;
      const totalCount = countOfQuestions < 10 ? 10 : countOfQuestions;
      const correct = state.answers.correct;

      const correctPercent = totalCount === 0 ? 0 : Math.round((correct * 100) / totalCount);
      const wrongPercent = 100 - correctPercent;

      if(countOfQuestions < 10) {
        const rest = 10 - countOfQuestions;
        state.answers.wrong = state.answers.wrong + rest;
      }
      state.recaluculate = correct * correctPercent;
      state.answers.correctPercent = correctPercent;
      state.answers.wrongPercent = wrongPercent;
    },
    addRecalculatedScore: (state: IGameState) => {
      state.score = state.score + state.recaluculate;
    },
    generateHandpickedQuestions: (state: IGameState) => {
      state.handpickedQuestions = shuffle(state.handpickedProducts.map((item: IHandpickedProduct) => {
        return shuffle([item.isSuccess, item.isFaild]);
      }));
    },
    generateQuestions: (state: IGameState) => {
      const successProducts = state.products!.isSuccess;
      const faildProducts = state.products!.isFaild;

      const _questions = [];
      const shuffleSuccessProducts = shuffle(successProducts);
      const shuffleFaildProducts = shuffle(faildProducts);
      const arrayLength = Math.min(shuffleSuccessProducts.length, shuffleFaildProducts.length);

      for (let i = 0; i < arrayLength - 1; i++) {
        const itemS = shuffleSuccessProducts[i];
        const itemF = shuffleFaildProducts[i];
        if (itemS && itemF) {
          _questions.push([itemS, itemF]);
        }
      }
      state.questions = _questions.map((item: Array<IProduct>) => shuffle(item));
      state.currentIndex = 0;
    },
    incrementCurrentIndex: (state: IGameState) => {
      state.currentIndex = state.currentIndex + 1;
    },
    incrementHandpickedCurrentIndex: (state: IGameState) => {
      state.currentHandpickedIndex = state.currentHandpickedIndex + 1;
    },
    onSelectAnswer: (state: IGameState, action: { payload: ISaveGameAnswers }) => {
      const data = action.payload;
      const index  = state.answers.data.length + 1;
      state.answers.data = state.answers.data.concat([{...data, index }]);

      const isCorrect = data.isCorrect;
      if (isCorrect) {
        state.answers.correct = state.answers.correct + 1;
        state.score = state.score + 100;
      } else {
        state.answers.wrong = state.answers.wrong + 1;
      }
    },
    stopTimer: (state: IGameState, action: { payload: boolean }) => {
      state.isStopped = action.payload;
    },
    changePlayerInfo: (state: IGameState, action: { payload: IPlayer | null}) => {
      state.player = action.payload as IPlayer;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(loadProductsAsync.fulfilled, (state, action) => {
      const successProducts = action.payload.filter((item: IProduct) => item.isSuccessfull);
      const faildProducts = action.payload.filter((item: IProduct) => !item.isSuccessfull);

      state.products = {
        isSuccess: successProducts,
        isFaild: faildProducts
      };
    })
    .addCase(loadHandpickedProductsAsync.fulfilled, (state, action) => {
      state.handpickedProducts = action.payload;
    })
  }
});

export const { clearUpGame, recalculateGame, addRecalculatedScore,
  generateQuestions, incrementCurrentIndex, incrementHandpickedCurrentIndex, generateHandpickedQuestions,
  onSelectAnswer, stopTimer, changePlayerInfo, clearUpGameInfo } = gameSlice.actions;
  
export const playerSelector = (state: RootState) => state.game.player;
export const answersSelector = (state: RootState) => state.game.answers;
export const gameScoreSelector = (state: RootState) => state.game.score;
export const productsSelector = (state: RootState) => state.game.products;
export const recaluculateSelector = (state: RootState) => state.game.recaluculate;
export const gameQuestionsSelector = (state: RootState) => state.game.questions;
export const questionsCurrentIndexSelector = (state: RootState) => state.game.currentIndex;
export const questionsCurrentHandpickIndexSelector = (state: RootState) => state.game.currentHandpickedIndex;
export const isGameStoppedSelector = (state: RootState) => state.game.isStopped;
export const handpickedQuestionsSelector = (state: RootState) => state.game.handpickedQuestions;

export const correctPercentAnswersSelector = (state: RootState) => state.game.answers.correctPercent;

export default gameSlice.reducer;
