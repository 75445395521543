import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import CircularProgress from "@mui/material/CircularProgress";
import { Breakpoint } from "@mui/material/styles";

interface IConfirmationDialog {
    open: boolean;
    loading?: boolean;
    message?: JSX.Element | string;
    title?: string;
    positiveBtnText?: string;
    negativeBtnText?: string;
    alert?: boolean;
    disabled?: boolean;
    maxWidth?: false | Breakpoint;
    onConfirm?: Function;
    onCancel: () => void;
}    

const ConfirmationDialog = (props: IConfirmationDialog) => {
    const { open, title, message, alert = false, loading, disabled = false, onCancel, negativeBtnText, positiveBtnText, onConfirm = () => null, maxWidth } = props;

    const dialogTitle = Boolean(title) ? title : "Attention";
    const negativeTxt = alert ? "Close" : Boolean(negativeBtnText) ? negativeBtnText : "No";
    const positiveTxt = Boolean(positiveBtnText) ? positiveBtnText : "Yes";
    const messageTxt = Boolean(message) ? message : "Are you sure you want to delete the selected item?";

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={(maxWidth !== undefined || maxWidth !== null) ? maxWidth : "xl"}
            onClose={onCancel}
        >
            <DialogTitle>
                <Box color='error.main'>
                    {dialogTitle}
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {messageTxt}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={disabled || loading} onClick={onCancel} autoFocus>{negativeTxt}</Button>
                {
                    !alert && <Button
                        disabled={disabled || loading}
                        color="error"
                        variant="outlined"
                        startIcon={loading && <CircularProgress size={20} sx={{color: '#fff'}} />}
                        onClick={() => onConfirm()}>
                        {positiveTxt}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
