import { motion, AnimatePresence } from "framer-motion";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, useMediaQuery, useTheme } from "@mui/material";
import { durationAnimation } from "../../constants";
import { IProduct } from "../../app/entities";

interface Props {
  isVisible: boolean;
  selectedProductId: string;
  onSelectProduct: Function;
  data: IProduct;
}

const ProductItem = (props: Props) => {
  const {
    data,
    isVisible,
    selectedProductId,
    onSelectProduct,
  } = props;
  const {
    id,
    name,
    description,
    productPicture,
    isSuccessfull
  } = data;

  const showAnswer = selectedProductId === id;
  const answerType = isSuccessfull ? "correct" : "wrong";
  const showProduct = isVisible || (!isVisible && showAnswer);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down(480));

  return (
    <AnimatePresence>
      {showProduct && (<>
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{
            type: "linear",
            duration: durationAnimation - 0.9,
          }}
          className="product-item-container"
        >
          <Card className="product-item" onClick={() => onSelectProduct(data)}>
            <CardActionArea>
              <CardMedia
                component="img"
                height={isSm ? 172 : 304}
                // width={isSm ? "100%" : "auto"}
                image={productPicture}
                alt="product"
              />
              <CardContent className="content">
                <h5>
                  {name}
                </h5>
                <h6>
                  {description}
                </h6>
              </CardContent>
            </CardActionArea>
          </Card>

          {showAnswer && (
            <motion.img
              initial={{ scale: 0 }}
              animate={{ scale: [1, 1.2, 1] }}
              transition={{
                type: "linear",
                duration: durationAnimation,
              }}
              alt="answer-img"
              src={`/img/game/product/${answerType}.svg`}
              className="answer-img"
            />
          )}
        </motion.div>
      </>
      )}
    </AnimatePresence>
  );
};

export default ProductItem;
