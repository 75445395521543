import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { configsDataSelector } from "../../features/configs/configsSlice";
import { changePlayerInfo, clearUpGameInfo } from "../../features/game/gameSlice";
import { loadLeaderboardAsync, top30 } from "../../features/game/leaderboardSlice";

import { getPadTime } from "../../helpers/timer";

const LeaderboardTimer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const configs = useAppSelector(configsDataSelector);

  const [timeLeft, setTimeLeft] = useState<number>(configs!.replay_time_in_seconds || 30);
  const seconds = getPadTime(timeLeft);

  useEffect(() => {
    dispatch(loadLeaderboardAsync({updateDisplayItems: false}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if( timeLeft === 0 ) {
      dispatch(changePlayerInfo(null));
      dispatch(clearUpGameInfo());
      dispatch(top30());
      navigate("/");
    }

    const interval = setInterval(() => {
      setTimeLeft((prev: number) => (prev === 0 ? 0 : prev - 1));
    }, 1000);
    
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return <>{seconds}s</>;
};

export default LeaderboardTimer;
