import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ILeaderBoardItem } from '../../app/entities';
import { getAllLeaderboard } from '../../app/services/firestore';
import { RootState } from '../../app/store';

interface ILeaderboardState {
    allItems?: Array<ILeaderBoardItem>;
    displayItems?: Array<ILeaderBoardItem>;
}

const initialState: ILeaderboardState = {}

export interface ILoadLeaderboard {
    updateDisplayItems: boolean;
}
export const loadLeaderboardAsync = createAsyncThunk('leaderboard/load', async (args: ILoadLeaderboard) => {
    return {
        items: await getAllLeaderboard(),
        updateDisplayItems: args.updateDisplayItems
    };
});

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        top30: (state) => {
            state.displayItems = state.allItems?.slice(0, 30);
        },
        addGameToLeaderboard: (state, action) => {
            const newGame: ILeaderBoardItem = action.payload;
            let allItems = state.allItems;
            if (allItems != null) {
                const oldGame = allItems.filter((item: ILeaderBoardItem) => item.playerId === newGame.playerId);
                
                if (oldGame.length === 0) {
                    allItems.push(newGame);
                } else {
                    //update info
                    const oldGameData = oldGame[0];
                    const needAddNewGame = newGame.score >= oldGameData.score;

                    if (needAddNewGame) {
                        allItems = allItems.map((item: ILeaderBoardItem) => {
                            if (item.playerId === newGame.playerId) {
                                item.name = newGame.name;
                                item.score = newGame.score;
                            }
                            return item;
                        })
                    }
                }

                allItems = allItems.sort((a, b) => {
                    if (a.score > b.score) return -1;
                    if (a.score < b.score) return 1;

                    return 0;
                });
                let position = 0;
                let playerIndex: number | null = null;
                const newAllItems = allItems.map((item: ILeaderBoardItem) => {
                    item.position = ++position;
                    if (item.playerId === newGame.playerId) {
                        playerIndex = item.position - 1;
                    }
                    return item;
                });

                if (playerIndex != null) {
                    if (playerIndex <= 10) {
                        state.displayItems = newAllItems.slice(0, 10);
                    } else {
                        const cuttedResult: Array<ILeaderBoardItem> = []; 
                        const firstThree = newAllItems.slice(0, 3);
                        cuttedResult.push(...firstThree);
                        cuttedResult.push(newAllItems[playerIndex - 3]);
                        cuttedResult.push(newAllItems[playerIndex - 2]);
                        cuttedResult.push(newAllItems[playerIndex - 1]);
                        cuttedResult.push(newAllItems[playerIndex]);
                        
                        if (newAllItems.length > (playerIndex + 1)) {
                            cuttedResult.push(newAllItems[playerIndex + 1]);
                        }
                        if (newAllItems.length > (playerIndex + 2)) {
                            cuttedResult.push(newAllItems[playerIndex + 2]);
                        }
                        if (newAllItems.length > (playerIndex + 3)) {
                            cuttedResult.push(newAllItems[playerIndex + 3]);
                        }
                        
                        state.displayItems = cuttedResult;
                    }
                } else {
                    state.displayItems = newAllItems.slice(0, 10);
                }
                state.allItems = newAllItems;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadLeaderboardAsync.fulfilled, (state, action) => {
            state.allItems = action.payload.items;
            if (action.payload.updateDisplayItems) {
                state.displayItems = state.allItems.slice(0, 30);
            }
        })
    }
});

export const { addGameToLeaderboard, top30 } = leaderboardSlice.actions;

export const displayItemsSelector = (state: RootState) => state.leaderboard.displayItems;

export default leaderboardSlice.reducer;
