import Box from "@mui/material/Box";
import { motion } from "framer-motion";

import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Loading = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down(480));

  return (
    <Box
      sx={{
        minHeight: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.img
        alt="logo"
        src="/img/logo.svg"
        style={{ height: isSm? '120px' : '152px'}}
        animate={{ scale: [1, 0.9, 1] }}
        transition={{ repeat: Infinity, type: "keyframes", duration: 1 }}
      />
    </Box>
  );
};

export default Loading;
