import { collection, doc, DocumentData, getDoc, getDocs, QueryDocumentSnapshot, query, where, getDocsFromServer, addDoc, setDoc, orderBy, limit } from "firebase/firestore";
import { IConfigs } from "../../features/configs/configsSlice";
import { db } from "../../helpers/firebase";
import { convertHandpickedProduct, convertToProduct, IGame, IHandpickedProduct, ILeaderBoardItem, IPlayer, IProduct } from "../entities";

export const loadConfig = async () => {
    const querySnapshot = await getDocs<IConfigs>(collection(db, "configs"));
    
    if (querySnapshot.empty) return null;

    return querySnapshot.docs[0].data();
};

export const loadAllProducts = async () => {
    const products: Array<IProduct> = [];

    const querySnapshot = await getDocs(collection(db, "products"));
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      products.push(convertToProduct(doc.id, doc.data()));
    });

    return products;
};

export const loadHandpickedProducts = async () => {
    const products: Array<IHandpickedProduct> = [];

    const querySnapshot = await getDocs(collection(db, "pairedProducts"));
    querySnapshot.forEach((doc: any) => {
        products.push(convertHandpickedProduct({ ...doc.data(), id: doc.id }));
    });

    return products;
};

export const loadCountOfPlayers = async () => {
    const docRef = doc(db, "configs", "itnIQvPsP2aEFBsHp2Ee");
    const querySnapshot = await getDoc(docRef);
    
    return querySnapshot.data()?.players_count || 0;
};


export const getPlayerId = async (playerData: IPlayer) => { 
    const q = query(collection(db, "players"), where("email", "==", playerData.email));
    const querySnapshot = await getDocsFromServer(q);

    if (querySnapshot.empty || playerData.isAnonymus) {
        //create new Player
        const newDoc = await addDoc(collection(db, "players"), playerData);
        return newDoc.id;
    } else {
        //update info
        const dataSnapShot = querySnapshot.docs[0];
        const playerId = dataSnapShot.id;
        const data = dataSnapShot.data();
        const id = dataSnapShot.id;

       await setDoc(doc(db, "players", id), {
            name: playerData.name,
            isAnonimus: playerData.isAnonymus,
            subscribe: data.subscribe || playerData.subscribe
        }, { merge: true });
       return playerId;
    }
};


export const addInfoToLeaderBoard = async (gameInfo: IGame) => { 
    const docRef = collection(db, "leaderboard");

    const q = query(docRef, where("playerId", "==", gameInfo.playerId));
    const querySnapshot = await getDocsFromServer(q);

    if (querySnapshot.empty) {
        // create new leaderboardItem
        const _leaderboardItem: ILeaderBoardItem = {
            playerId: gameInfo.playerId,
            name: gameInfo.name,
            score: gameInfo.score
        }

        await addDoc(docRef, _leaderboardItem);
    } else {
        //update info
        const dataSnapShot = querySnapshot.docs[0];
        const data = dataSnapShot.data();
        const id = dataSnapShot.id;

        const isLess = gameInfo.score < data.score;

       await setDoc(doc(db, "leaderboard", id), {
            name: isLess ? data.name : gameInfo.name,
            playerId: gameInfo.playerId,
            score: isLess ? data.score : gameInfo.score,
        }, { merge: true });
    }

    return ;
};

export const getAllLeaderboard = async () => {
    const docRef = collection(db, "leaderboard");
    let position: number = 0;
    const results: Array<ILeaderBoardItem> = []; 
    const querySnapshot = await getDocs(query(docRef, orderBy("score", "desc")));
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        const item: ILeaderBoardItem = {
            playerId: data.playerId,
            name: data.name,
            score: data.score,
            position: ++position
        };
        results.push(item);
    });

    return results;
};

export const getLeaderboardInfo = async (playerId: string | null) => {
    const docRef = collection(db, "leaderboard");

    let results: Array<ILeaderBoardItem> = []; 
    let playerIndex: number | null = null;
    let position = 0;

    if (playerId == null) {
        const q = query(docRef, orderBy("score", "desc"), limit(10));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const item: ILeaderBoardItem = {
                playerId: data.playerId,
                name: data.name,
                score: data.score,
                position: ++position
            };
            results.push(item);
        });
    } else {
        const q = query(docRef, orderBy("score", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const item: ILeaderBoardItem = {
                playerId: data.playerId,
                name: data.name,
                score: data.score,
                position: ++position
            };
            results.push(item);
            if (item.playerId === playerId) {
                playerIndex = results.length - 1;
            }
        });
        if (playerIndex != null) {
            if (playerIndex <= 10) {
                results = results.slice(0, 10);
            } else {
                const cuttedResult: Array<ILeaderBoardItem> = []; 
                const firstThree = results.slice(0, 3);
                cuttedResult.push(...firstThree);
                cuttedResult.push(results[playerIndex - 3]);
                cuttedResult.push(results[playerIndex - 2]);
                cuttedResult.push(results[playerIndex - 1]);
                cuttedResult.push(results[playerIndex]);
                
                if (results.length > (playerIndex + 1)) {
                    cuttedResult.push(results[playerIndex + 1]);
                }
                if (results.length > (playerIndex + 2)) {
                    cuttedResult.push(results[playerIndex + 2]);
                }
                if (results.length > (playerIndex + 3)) {
                    cuttedResult.push(results[playerIndex + 3]);
                }
                
                results = cuttedResult;
            }
        } else {
            results = results.slice(0, 10);
        }
    }

    return results;
};

