import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

import Loading from "../../app/Shared/Loading";
import { GameRoutes } from "../../constants/routes";
import { configsDataSelector } from "../../features/configs/configsSlice";

import './styles.scss';

const GameLoading = () => {
  const navigate = useNavigate();
  const configs = useAppSelector(configsDataSelector);

  const animation_loading_time =  configs!.animation_loading_time?  configs!.animation_loading_time * 1000 : 3000;

  useEffect(()=> {
    const timer = setTimeout(() => {
      navigate(GameRoutes.Game.main);
    }, animation_loading_time); 

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gameLoading-container">
      <Loading />

      <img
        alt="logo"
        className="logo-leaderboard loading-logo"
        src="/img/leaderboard-logo.svg"
      />
    </div>
  );
};

export default GameLoading;
