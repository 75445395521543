import { Box, Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material';

interface Props {
  title: string;
  isLoading: boolean;
  data: any;
  getData: () => void;
}

const CardInfo = (props: Props) => {
  const { title, isLoading, data, getData } = props;

  let content = <Box className='d-flex align-items-centerjustify-content-center'>
    <CircularProgress color='primary' disableShrink size={25}/>
  </Box>;
  if(!props.isLoading) content = <b>{ data }</b>;

  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent sx={{ paddingBottom: 0 }}>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          { title}
        </Typography>
        
        <Typography variant="body2">
          { content }
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button 
          size="small" 
          disabled={isLoading}
          onClick={ getData }>
          Refresh
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardInfo;
