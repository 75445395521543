import { Outlet } from "react-router-dom";

import CheckAuth from "../../app/Shared/CheckAuth";
import './AdminContainer.scss';

function AdminContainer() {
  return (
    <CheckAuth>
    <div className="App">
    
      <Outlet />
    </div>
    </CheckAuth>
  );
}

export default AdminContainer;
