import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { useAppDispatch } from "../../../app/hooks";
import { logOut } from "../../../features/admin/adminSlice";
import { AdminRoutes } from "../../../constants/routes";
import Box from "@mui/material/Box";

const DashboardHeader = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const onLogout = () => {
    dispatch(logOut);
    history(AdminRoutes.Login.main);
  };

  return (
    <header className={`app-header`}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
           <Box sx={{fontSize: '20px'}}>Admin Dashboard</Box>
        </Grid>
        <Grid item>
          <Button onClick={onLogout}>Logout</Button>
        </Grid>
      </Grid>
    </header>
  );
};

export default DashboardHeader;
