// import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "./helpers/firebase";

import { GameRoutes, AdminRoutes } from "./constants/routes";
import AdminContainer from "./pages/Admin/AdminContainer";
// import Loading from "./app/Shared/Loading";
import MainContainer from "./pages/MainContainer";
import { theme } from "./constants/muiTheme";

import AdminDashboard from "./pages/Admin/Dashboard";
import AdminLoginPage from "./pages/Admin/Login";
import LeaderBoard from "./pages/LeaderBoard";
import GameOver from "./pages/GameOver";
import GamePage from "./pages/Game";
import SendResultPage from "./pages/SendResultPage";
import GameLoading from "./pages/GameLoading";

const container = document.getElementById("root")!;
const root = createRoot(container);

// const AdminLoginPage = React.lazy(() => import("./pages/Admin/Login"));
// const AdminDashboard = React.lazy(() => import("./pages/Admin/Dashboard"));

// const LeaderBoard = React.lazy(() => import("./pages/LeaderBoard"));
// const Game = React.lazy(() => import("./pages/Game"));
// const GameOver = React.lazy(() => import("./pages/GameOver"));

root.render
(
  <div className="App">
  {/* //   <div className="App-container"> */}
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            {/* <Suspense fallback={<Loading />}> */}
              <Routes>
                <Route
                  path={GameRoutes.LeaderBoard.short}
                  element={<MainContainer />}
                >
                  <Route index element={<LeaderBoard />} />
                  <Route path={GameRoutes.SendResult.short} element={<SendResultPage />} />
                  <Route path={GameRoutes.GameLoading.short} element={<GameLoading />} />
                  <Route path={GameRoutes.Game.short}>
                    <Route index element={<GamePage />} />
                    <Route
                      path={GameRoutes.GameOver.short}
                      element={<GameOver />}
                    />
                  </Route>
                </Route>
                
                <Route
                  path={AdminRoutes.Login.short}
                  element={<AdminContainer />}
                >
                  <Route index element={<AdminLoginPage />} />
                  <Route
                    path={AdminRoutes.Dashboard.short}
                    element={<AdminDashboard />}
                  />
                </Route>
              </Routes>
            {/* </Suspense> */}
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
  {/* //   </div> */}
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
