import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from '@mui/material/useMediaQuery';

import { ILeaderBoardItem } from "../../app/entities";
import Loading from "../../app/Shared/Loading";
import LeaderBoardActions from "./Actions";
import PlayerItem from "./Item";

import "./styles.scss";

import { displayItemsSelector } from "../../features/game/leaderboardSlice";
import { useAppSelector } from "../../app/hooks";
import useHeaderSize from "../../helpers/useHeaderSize";

const LeaderBoard = () => {
  const location = useLocation();
  const afterGameView = Boolean(location.search);

  const [isLoading, setLoading] = useState<boolean>(true);
  const players = useAppSelector(displayItemsSelector);
  const { header, footer } = useHeaderSize({isLoading});

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1024));

  useEffect(() => setLoading(players == null), [players]);

  const heigthOffset = isMd? header + footer : header + 88 + 20 + 2*24;

  return (
    <>
      <div className="leaderboard-container">
        <div 
          className="scroll-container"
          style={{ height: `calc(100vh - ${heigthOffset}px)`}}
        >
          {
            isLoading ? (
              <Box 
                style={{ height: '100%'}}
                className='d-flex align-items-center justify-content-center'>
                <Loading />
              </Box>
            ) : (
              players?.map((player: ILeaderBoardItem, index: number) => (
                <PlayerItem
                  key={`playerItem-${player.playerId}-${index}`}
                  index={index}
                  prevPlace={index === 0 ? 0 : players![index - 1].position!}
                  afterGameView={afterGameView}
                  {...player}
                />
              ))
            )
          }
        </div>
      </div>

      { !isLoading && <LeaderBoardActions afterGameView={afterGameView} /> }

      <img
        alt="logo"
        className="logo-leaderboard"
        src="/img/leaderboard-logo.svg"
      />
    </>
  );
};

export default LeaderBoard;
