import { useRef } from "react";
import Box from "@mui/material/Box";
// import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';

// import LoadingButton from '@mui/lab/LoadingButton';

// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { AdminLoginState, AdminLoginErrors } from "./index";
import Button from "../../../app/Shared/Button";

interface Props {
  values: AdminLoginState;
  errors: AdminLoginErrors;
  loading: boolean;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  doLogin: () => void;
};

export const LoginForm = (props: Props) => {
  const { values, errors, loading, handleChange, doLogin } = props;

  // const [showPassword, setShowPassword] = useState<boolean>(false);
  const passwordField = useRef<HTMLInputElement>(null);

  // const handleClickShowPassword = () => {
  //   setShowPassword(true);
  // };

  // const handleMouseDownPassword = () => {
  //   setShowPassword(false);
  // };

  const handleEnterKey = (field: string) => (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      switch (field) {
        case "email":
          passwordField?.current?.focus();
          break;

        case "password":
          doLogin();
          break;

        default:
          break;
      }
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
        <FormControl sx={{ m: 1 }} variant="filled">
          {/* <InputLabel htmlFor="filled-adornment-email">Email</InputLabel> */}
          <OutlinedInput
            id="filled-adornment-email"
            placeholder="Email"
            className='input'
            value={values.email}
            name="email"
            onChange={handleChange}
            onKeyUp={handleEnterKey("email")}
            error={errors && Boolean(errors.email)}
          />
          <FormHelperText className='error'>{errors.email}</FormHelperText>
        </FormControl>
        <FormControl sx={{ m: 1 }} variant="filled">
          {/* <InputLabel htmlFor="filled-adornment-password">Password</InputLabel> */}
          <OutlinedInput
            id="filled-adornment-password"
            placeholder="Password"
            type={"password"} // showPassword ? "text" : "password"
            value={values.password}
            name="password"
            onChange={handleChange}
            onKeyUp={handleEnterKey("password")}
            inputRef={passwordField}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={handleClickShowPassword}
            //       onMouseDown={handleMouseDownPassword}
            //       edge="end"
            //     >
            //       {showPassword ? <VisibilityOff /> : <Visibility />}
            //     </IconButton>
            //   </InputAdornment>
            // }
            error={errors && Boolean(errors.password)}
          />
          <FormHelperText className='error'>{errors.password}</FormHelperText>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: '250px', "& > button": { m: 1, width: '100%' } }}>
        <Button
          size="small"
          style={{ width: '100%' }}
          onClick={doLogin}
          loading={loading}
          disabled={loading}
        >
          Login
        </Button>
      </Box>
    </>
  );
};