import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadConfig } from '../../app/services/firestore';
import { RootState } from '../../app/store';

export interface IConfigs {
  game_time_in_seconds?: number;
  min_questions_for_new_game?: number;
  animation_loading_time?: number;
  replay_time_in_seconds?: number;
}

interface ConfigsState {
  data?: IConfigs;
  isSecFormat: boolean;
}

const initialState: ConfigsState = {
  isSecFormat: true
};

export const loadConfigAsync = createAsyncThunk(
  'config/load',
  async () => await loadConfig()
);

export const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setConfigs: (state: ConfigsState, action: {payload: IConfigs }) => {
      const payload = action.payload;
      state.data = {...payload };
      state.isSecFormat = (payload as { game_time_in_seconds: number })?.game_time_in_seconds < 60;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadConfigAsync.fulfilled, (state, action) => {
      if (action.payload != null) {
        const newConfig = action.payload as IConfigs;
        state.data = newConfig;
        state.isSecFormat = (newConfig.game_time_in_seconds ?? 0) < 60;
      }
    })
  }
});

export const { setConfigs } = configsSlice.actions;
export const configsDataSelector = (state: RootState) => state.configs.data;
export const isSecFormatSelector = (state: RootState) => state.configs.isSecFormat;

export default configsSlice.reducer;
