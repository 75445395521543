import Grid from "@mui/material/Grid";

import NumberOfTotalPlayers from "./NumberOfTotalPlayers";
import DashboardHeader from "./Header";
import NumberOfAverageAnswers from "./NumberOfAverageAnswers";
import Synchronize from "./Synchronize";

const AdminDashboard = () => {

  return (
    <>
      <DashboardHeader />

      <div className="admin-panel-container">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Synchronize />
          </Grid>
          <Grid item>
            <NumberOfTotalPlayers />
          </Grid>
          <Grid item>
            <NumberOfAverageAnswers />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AdminDashboard;
