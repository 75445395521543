export const GameRoutes = {
    LeaderBoard: {
        main: "/",
        short: "/"
    },
    Game: {
        main: "/game",
        short: "game"
    },
    GameOver: {
        main: "/game/over",
        short: "over"
    },
    SendResult: {
        main: "/sendResult",
        short: "sendResult"
    },
    GameLoading: {
        main: "/loading",
        short: "loading"
    },
};

export const AdminRoutes = {
    Login: {
        main: "/admin",
        short: "/admin"
    },
    Dashboard: {
        main: "/admin/dashboard",
        short: "dashboard"
    }
};