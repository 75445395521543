import { useState, useEffect } from 'react';
import { loadCountOfPlayers } from '../../../app/services/firestore';

import CardInfo from "./CardInfo";

const NumberOfTotalPlayers = () => {
  const [playersCount, setPlayersCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect( () => {
    getPlayersCount();
  }, []);

  const getPlayersCount  = async () => {
    const _playersCount = await loadCountOfPlayers();
    setPlayersCount(_playersCount);
    setLoading(false);
  };

  return (
    <CardInfo 
      title='Number Of Total Players'
      data={ playersCount }
      isLoading={ isLoading }
      getData={ getPlayersCount }
    />
  );
};

export default NumberOfTotalPlayers;
