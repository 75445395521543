import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  gameScoreSelector,
  recaluculateSelector,
  addRecalculatedScore,
  playerSelector,
  answersSelector,
} from "../../features/game/gameSlice";
import { GameRoutes } from "../../constants/routes";
import { useSaveGameInfo } from "../../helpers/useGameHook";
import Button from "../../app/Shared/Button";

const TimeOverCalculation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveGameInfo = useSaveGameInfo();

  const score = useAppSelector(gameScoreSelector);
  const answers = useAppSelector(answersSelector);
  const scoreRecalculate =
    useAppSelector(recaluculateSelector) || (0 as number);
  const player = useAppSelector(playerSelector);

  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [resultScore, setResultScore] = useState<number>(score);
  const [showRecalculateScore, setShowRecalculateScore] =
    useState<boolean>(false);
  const [scoreIsrecalculated, setScoreIsrecalculated] =
    useState<boolean>(false);
  const isAnimated =
    score + scoreRecalculate === resultScore &&
    score > 0 &&
    scoreRecalculate > 0;
  const hasNoAnswers = answers.data.length === 0 || answers.correct === 0;

  useEffect(() => {
    const interval = setTimeout(() => {
      setShowRecalculateScore(true);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let navigateInt: any = null;

    if (!isAnimated && showRecalculateScore && !scoreIsrecalculated) {
      setTimeout(() => {
        !isAnimated &&
          setResultScore((prev: number) => {
            return prev === score + scoreRecalculate ? prev : prev + 1;
          });
      }, 1);
    }

    if (isAnimated) setScoreIsrecalculated(true);

    return () => {
      if (navigateInt) clearTimeout(navigateInt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimated, resultScore, scoreRecalculate, showRecalculateScore]);

  useEffect(() => {
    if (scoreIsrecalculated) {
      dispatch(addRecalculatedScore());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreIsrecalculated]); //, resultScore, isAnimated

  useEffect(() => {
    if (scoreIsrecalculated || hasNoAnswers) {
      setShowBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score, scoreIsrecalculated]);

  const goSendResult = () => {
    if (player && !player.isAnonymus) {
      setDisabled(true);
      saveGameInfo(player, true);
    } else {
      navigate(GameRoutes.SendResult.main);
    }
  };

  return (
    <>
      <div className="recalculation">
        <div className="game-score d-flex align-items-center">
          <div className="score-text">Your Score:</div>
          <motion.img
            className="coin-img"
            initial={{ scale: 1 }}
            animate={{ scale: 1.2 }}
            transition={{
              repeat: 5,
              repeatType: "reverse",
              duration: 1,
            }}
            alt="players"
            src="/img/players/coin.svg"
          />
          <Box>{resultScore}</Box>

          {showRecalculateScore && scoreRecalculate > 0 && (
            <motion.div 
              className="plus-score"
              animate={{ opacity: showBtn ? 0 : 1 }}>
              <img alt="plus" src="/img/game/plus.svg" className="plus" />
              <span>{scoreRecalculate}</span>
            </motion.div>
          )}
        </div>
      </div>
      
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: showBtn? 1 : 0 }}
          className="show-btn-container">
          <Button
            loading={disabled}
            disabled={disabled}
            className="show-btn"
            onClick={goSendResult}
          >
            Yes, show me how!
          </Button>
        </motion.div>
    
    </>
  );
};

export default TimeOverCalculation;
