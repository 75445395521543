import { collection, Timestamp, addDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import { IGame, IPlayer } from "../app/entities";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addInfoToLeaderBoard, getPlayerId } from "../app/services/firestore";
import { GameRoutes } from "../constants/routes";
import { configsDataSelector } from "../features/configs/configsSlice";
import {
  answersSelector,
  changePlayerInfo,
  gameScoreSelector,
} from "../features/game/gameSlice";
import { addGameToLeaderboard } from "../features/game/leaderboardSlice";

import { db } from "./firebase";

export const useSaveGameInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const answers = useAppSelector(answersSelector);
  const score = useAppSelector(gameScoreSelector);
  const configs = useAppSelector(configsDataSelector);

  const setGameInfo = async (data: IPlayer, withDelay: boolean = false) => {
    const playerId = await getPlayerId(data);

    dispatch(addGameToLeaderboard({
      playerId,
      name: data.name,
      score
    }));
    dispatch(changePlayerInfo({ ...data, playerId }));

    const gameData: IGame = {
      playerId,
      name: data.name, //for leaderboard if use more then 1 name
      email: data.email, //for email
      total: answers.data.length,
      score,
      date: Timestamp.fromDate(new Date()),
      correct: answers.correct,
      wrong: answers.wrong,
      answers: answers.data,
      correctPercent: answers.correctPercent,
      wrongPercent: answers.wrongPercent,
      time: configs?.game_time_in_seconds
    } as any;

    await addDoc(collection(db, "game"), gameData);
    await addInfoToLeaderBoard(gameData);

    if (withDelay) {
      setTimeout(() => {
        navigate(`${GameRoutes.LeaderBoard.main}?=${playerId}`);
      }, 4000);
    } else {
      navigate(`${GameRoutes.LeaderBoard.main}?=${playerId}`);
    }
  };

  return async (playerData: IPlayer, withDelay: boolean = false) => {
    await setGameInfo(playerData, withDelay);
  };
};
