import ResultsForm from './Form';

const SendResults = () => {
  
  return (
    <div className="send-result">
      <div className="subtitle">
        Want to stop guessing and start predicting with 87% accuracy?
      </div>
      <div className="title">Unlock an extra 20% discount when you sign up to Prelaunch.com</div>

      <ResultsForm />
    </div>
  );
};

export default SendResults;
