import { createTheme } from '@mui/material/styles';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../styles/_variables.scss';

export const theme = createTheme({
  palette: {
    primary: {
      main: vars.primary.rgba,
    },
    error: {
      main: vars.error.rgba
    }, 
    // divider: vars.grayLightest.rgba,
  },
  typography: {
    fontFamily: [
      'Play',
      'sans-serif !important',
    ].join(','),
    fontSize: 16,
  },
  breakpoints: {
    values: {
      xs: vars.xs,
      sm: vars.sm,
      md: vars.md,
      lg: vars.lg,
      xl: vars.xl,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            color: vars.placeholder.rgba,
            height: '22px', //32px
            padding: '23px 40px',     //'28px 40px'        
            fontWeight: 400,
            fontSize: '22px', //32px
            lineHeight: '22px', //32px
            background: '#252525',
            borderRadius: '56px',
            '::placeholder': {
              color: vars.placeholder.rgba
            }
          },
          '& input:valid + fieldset': {
            borderColor: '#515151',
            borderWidth: 3,
            borderRadius: '56px',
          },
          '& input: -internal-autofill-selected': {
            borderRadius: '56px',
            backgroundColor: '#252525 !important',
            "-webkit-text-fill-color": vars.placeholder.rgba,
            "-webkit-box-shadow": '0 0 0 1000px #252525 inset !important'
          },
          '&: hover': {
            '& input:valid + fieldset':{
              borderColor: '#5CEBDA',
            }
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '17px !important'
        },
        contained: {
          color: '#fff',
          boxShadow: 'none',
          fontFamily: 'Play, sans-serif !important',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '27px',
          background: 'linear-gradient(1180deg, #5CEBDA 1.81%, #099484 100.12%)',
          borderRadius: '60px',
          textTransform: 'capitalize',
        },
        // containedSizeSmall: {
        //   padding: '17px' //25px
        // },
        // containedSizeLarge: {
        //   lineHeight: '48px',
        //   padding: '17px' //20px
        // },
        startIcon: {
          margin: '0 16px 0 0'
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          padding: '17px'
        },
        loadingIndicator: {
          color: "#ffffff"
        },
        loading: {
          color: "transparent !important"
        }
      }
    }
    // MuiFormHelperText: {
    //   styleOverrides: {
    //     root: {
    //       marginLeft: 0
    //     }
    //   }
    // }
  },
});