import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props extends LoadingButtonProps {
  children: string;
  className?: string;
  iconSrc?: string;
}

const Button = (props: Props) => {
  const { className, iconSrc, children, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="app-btn">
      <LoadingButton
        className={className}
        sx={{
          padding: isSm ?  '10px 0' : isMd? '14px 0' : "25px 0"
        }}
        variant="contained"
        {...rest}
        startIcon={ iconSrc && <img alt='btn-img' src={iconSrc} width={isMd? 32 : 35} height={isMd? 32 : 35} /> }
      >
        <img
          alt="highlights"
          src="/img/btn-highlights.svg"
          className="btn-highlights"
        />
        {children}
      </LoadingButton>
    </div>
  );
};

export default Button;
