import { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";

import CardInfo from "./CardInfo";

const NumberOfAverageAnswers = () => {
  const [count, setCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect( () => {
    getAwerageCount();
  }, []);

  const getAwerageCount  = async () => {
    setLoading(true);

    const functions = getFunctions();
    const numberOfAverageAnswers = httpsCallable(functions, 'numberOfAverageAnswers');

      numberOfAverageAnswers(30)
        .then((result) => {
          const _data = result.data as number;
          setCount(_data);
          setLoading(false);
      });
  };

  return (
    <CardInfo 
      title='Number Of Average Answers Within 30 sec'
      data={ count }
      isLoading={ isLoading }
      getData={ getAwerageCount }
    />
  );
};

export default NumberOfAverageAnswers;
