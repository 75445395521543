import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from '@mui/material/useMediaQuery';

import { GameRoutes } from "../../../constants/routes";
import LeaderBoardTitle from "./LeaderBoardTitle";
import GameTimer from "../../../pages/Game/Timer";
import Players from "./Players";
import GameInfo from "./GameInfo";

const Header = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const logoVariant = isSm? '-sm' : '';

  const location = useLocation();
  const isLeaderBoard = location.pathname === GameRoutes.LeaderBoard.main;
  const isGamePage = location.pathname === GameRoutes.Game.main;
  const isGameOverPage = location.pathname === GameRoutes.GameOver.main;
  const isSendResult = location.pathname === GameRoutes.SendResult.main;

  let center = null;
  let right = null;
  let mainClassName = '';

  if (isLeaderBoard) {
    mainClassName = 'app-header-leaderboard';
    center = <LeaderBoardTitle />;
    right = <Players />;
  } else if (isGamePage || isGameOverPage || isSendResult) {
    mainClassName = 'app-header-game';
    center = isSendResult ? null : <GameTimer />;
    right =  <GameInfo />;
  }

  if (location.pathname === GameRoutes.GameLoading.main) {
    return null;
  } else {
    return (
      <header className={`app-header ${mainClassName}`}>
        <Grid 
          container 
          alignItems="center" 
          justifyContent="space-between"
          >
          <Grid item className='logo'>
            <img alt='logo' src={`/img/logo${logoVariant}.svg`} />
          </Grid>
          <Grid item className='main-part'>
            { center }
          </Grid>
          <Grid item className='info-part'>
            { right }
          </Grid>
        </Grid>
      </header>
    );
  }
};

export default Header;
