import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from '@mui/material/useMediaQuery';

const LeaderBoardTitle = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      className="d-flex align-items-center justify-content-center"
      sx={{
        background: "#282828",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        borderRadius: "12px",
        width: isMd ? '100%' : '856px', 
        padding: isMd ? "11px" : "14px",
        display: "flex",
      }}
    >
      <Box>
        <img alt="star" src="/img/stars/small.svg" width={isSm ? 15 : 'auto'} />
      </Box>
      <Box sx={{ px: isMd ? "24px" : "25px" }}>
        <img alt="star" src="/img/stars/large.svg" width={isSm ? 22 : 'auto'} />
      </Box>
      <Box
        sx={{
          fontWeight: "700",
          fontSize: isSm? '16px' : isMd ? "20px" : "28px",
          lineHeight: isSm? '19px' : isMd ? "23px" : "32px",
        }}
      >
        Leaderboard
      </Box>
      <Box sx={{ px: isMd ? "24px" : "25px" }}>
        <img alt="star" src="/img/stars/large.svg" width={isSm ? 22 : 'auto'} />
      </Box>
      <Box>
        <img alt="star" src="/img/stars/small.svg" width={isSm ? 15 : 'auto'} />
      </Box>
    </Box>
  );
};

export default LeaderBoardTitle;
