import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';

import Button from '../../app/Shared/Button';
import { GameRoutes } from '../../constants/routes';
import { clearUpGameInfo, changePlayerInfo } from '../../features/game/gameSlice';
import LeaderboardTimer from './Timer';

interface Props {
  afterGameView: boolean;
}

const LeaderBoardActions = (props: Props) => {
   const { afterGameView } = props;
   const dispatch = useAppDispatch();
   const navigate = useNavigate();

   const btnName = afterGameView ? "New Game" : "Start Game";

   const doReplay = () => {
    dispatch(clearUpGameInfo());
    scrollTop();
    navigate(GameRoutes.Game.main);
   };

   const doNewGame = () => {
    if (afterGameView) {
      dispatch(changePlayerInfo(null));
    }
    dispatch(clearUpGameInfo());
    scrollTop();
    navigate(GameRoutes.GameLoading.main);
   };

   const scrollTop = () => {
    const footerEl = document.getElementsByClassName('scroll-container');
    footerEl[0].scrollTo(0, 0);
   }
  
  return (
    <div className="leaderboard-actions">
      {
        afterGameView && <>
          <div className='leaderboard-timer'>
            <LeaderboardTimer />
          </div>
          <div className='replay-btn-container'>
            <Button 
              iconSrc='/img/replay.svg'
              className='replay-btn'
              onClick={doReplay}
            >
              Replay
            </Button>
          </div>
        </>
      }
      <div className='new-game-container'>
        <Button 
          iconSrc='/img/play.svg'
          className='new-game-btn'
          onClick={doNewGame}
        >
          { btnName }
        </Button>
      </div>
    </div>
  );
};

export default LeaderBoardActions;
