import { useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion"

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { durationAnimation } from "../../constants";
import {
  incrementCurrentIndex,
  gameQuestionsSelector, onSelectAnswer, questionsCurrentIndexSelector, incrementHandpickedCurrentIndex, questionsCurrentHandpickIndexSelector, handpickedQuestionsSelector,
} from "../../features/game/gameSlice";

import ProductItem from "./ProductItem";
import "./styles.scss";
import { IProduct } from "../../app/entities";

const GamePage = () => {
  const questions = useAppSelector(gameQuestionsSelector);
  const handpickedQuestions = useAppSelector(handpickedQuestionsSelector);
  const questionsCurrentIndex = useAppSelector(questionsCurrentIndexSelector);
  const questionsCurrentHandpickIndex = useAppSelector(questionsCurrentHandpickIndexSelector);
  const dispatch = useAppDispatch();
  const [handpickedCount, setHandpickedCount] = useState(0);

  const [selectedProductId, setSelectedProduct] = useState<string>('');
  const [selectedCorrectProduct, setCorrectProduct] = useState<null | boolean>(null);

  const isVisible = useMemo(() => !Boolean(selectedProductId), [selectedProductId]);

  const onSelectProduct = (data: IProduct, questions: Array<IProduct>) => {
    if(!Boolean(selectedProductId)) {
        setSelectedProduct(data.id);
        setCorrectProduct(data.isSuccessfull);
        // dispatch(stopTimer(true));
        dispatch(onSelectAnswer({
            successfull: questions.filter((item: IProduct) => item.isSuccessfull)[0],
            faild: questions.filter((item: IProduct) => !item.isSuccessfull)[0],
            isCorrect: data.isSuccessfull
        }));
        
        setTimeout(() => {
          setSelectedProduct('');
          setCorrectProduct(null);

          if (data.isHandpicked) {
            dispatch(incrementHandpickedCurrentIndex());
            setHandpickedCount(handpickedCount + 1);
          } else {
            dispatch(incrementCurrentIndex());
          }
          // dispatch(stopTimer(false));
        }, durationAnimation * 1000);
    }
  };

  const textTitle = selectedCorrectProduct === null ? 'Guess which product was successful?' : selectedCorrectProduct? `Yeap, that's right!` : 'Oops! Not this one';

  return (
    <div className="gamePage-container">
        <div id='gamePage-timer' />
        <div className="title">{textTitle}</div>

      <motion.div
        className="products-container"
        initial={{ opacity: 0, translateY: "100px" }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{
          type: "linear",
          duration: durationAnimation,
        }}
      >
        {
          (handpickedCount !== 3) && handpickedQuestions![questionsCurrentHandpickIndex] &&
          <>
              <ProductItem
                isVisible={isVisible} 
                selectedProductId={selectedProductId}
                onSelectProduct={(_data: IProduct) => onSelectProduct(_data, handpickedQuestions![questionsCurrentHandpickIndex])}
                data={ handpickedQuestions![questionsCurrentHandpickIndex][0] }
              />

              <AnimatePresence>
                  {
                    isVisible && (
                        <motion.img 
                          alt="img" 
                          src="/img/game/vs.svg" 
                          className="vs"
                          animate={{ opacity: 1}}
                          exit={{ opacity: 0, width: 0 }}
                          transition={{
                            type: "linear",
                            duration: 0.1 //durationAnimation - 0.6
                          }}
                        />
                  )}
                </AnimatePresence>

              <ProductItem 
                isVisible={isVisible}
                selectedProductId={selectedProductId}
                onSelectProduct={(_data: IProduct) => onSelectProduct(_data, handpickedQuestions![questionsCurrentHandpickIndex])}
                data={ handpickedQuestions![questionsCurrentHandpickIndex][1] } 
              />
            </>
        }
        { 
          handpickedCount >= 3 && questions![questionsCurrentIndex] &&
            <>
              <ProductItem
                isVisible={isVisible} 
                selectedProductId={selectedProductId}
                onSelectProduct={(_data: IProduct) => onSelectProduct(_data, questions![questionsCurrentIndex])}
                data={ questions![questionsCurrentIndex][0] } 
              />

              <AnimatePresence>
                  {
                    isVisible && (
                        <motion.img 
                          alt="img" 
                          src="/img/game/vs.svg" 
                          className="vs"
                          animate={{ opacity: 1}}
                          exit={{ opacity: 0, width: 0 }}
                          transition={{
                            type: "linear",
                            duration: 0.1 //durationAnimation - 0.6
                          }}
                        />
                  )}
                </AnimatePresence>

              <ProductItem 
                isVisible={isVisible}
                selectedProductId={selectedProductId}
                onSelectProduct={(_data: IProduct) => onSelectProduct(_data, questions![questionsCurrentIndex])}
                data={ questions![questionsCurrentIndex][1] } 
              />
            </>
        }
      </motion.div>
    </div>
  );
};

export default GamePage;
