import { motion } from "framer-motion";

import SendResults from "./Form";

import "./styles.scss";

const SendResultPage = () => {
  return (
    <div className="send-result-container d-flex flex-column align-items-center">
      <motion.div
        layout
        initial={{ opacity: 0, height: "auto" }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, height: 0 }}
        transition={{
          type: "linear",
        }}
      >
        <SendResults />
      </motion.div>
    </div>
  );
};

export default SendResultPage;
