import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { configsDataSelector, loadConfigAsync } from "../features/configs/configsSlice";
import Header from "../app/Shared/Header";
import Loading from "../app/Shared/Loading";
import { gameQuestionsSelector, handpickedQuestionsSelector, initDataLoading } from "../features/game/gameSlice";
import { IProduct } from "../app/entities";
import useHeaderSize from "../helpers/useHeaderSize";
import { loadLeaderboardAsync, top30 } from "../features/game/leaderboardSlice";
import { GameRoutes } from "../constants/routes";

function MainContainer() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = React.useState(true);
  const [loadedImgs, setLoadedImgs] = React.useState(false);
  const config = useAppSelector(configsDataSelector);
  const questions = useAppSelector(gameQuestionsSelector);
  const handpickedQuestions = useAppSelector(handpickedQuestionsSelector);
  const dispatch = useAppDispatch();
  const { header } = useHeaderSize({});

  React.useEffect(() => {
    dispatch(loadConfigAsync());
    dispatch(loadLeaderboardAsync({updateDisplayItems: true}));
    window.onpopstate = function(e: any) {
      dispatch(top30());
      navigate(GameRoutes.LeaderBoard.main);
    };

    window.addEventListener('blur', () => {
     navigate('/');
    });

    window.onload = () => {
      setLoadedImgs(true);
    };  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (config != null) {
      dispatch(initDataLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  React.useEffect(() => {
    setLoading(questions == null && !loadedImgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, loadedImgs]);

  return (
    <>
      <Header />

      <div 
        className="App-container" 
        style={{ minHeight: `calc(100vh - ${header + 5 }px)` }}>
        {
          isLoading ? <Loading /> : <Outlet />
        }
      </div>
      <div style={{display: "none"}}>
        {
          handpickedQuestions?.map((item: Array<IProduct>, index: number) => {
            return (
              <div key={item[0].productPicture + index}>
                <img alt='pic' src={item[0].productPicture} />
                <img alt='pic' src={item[1].productPicture} />
              </div>
            );
          })
        }
        {
          questions?.map((item: Array<IProduct>, index: number) => {
            return (
              <div key={item[0].productPicture + index}>
                <img alt='pic' src={item[0].productPicture} />
                <img alt='pic' src={item[1].productPicture} />
              </div>
            );
          })
        }
      </div>
    </>
  );
}

export default MainContainer;
